<template>
  <div class="row mt-4" id="notification">
    <div class="col-12">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0">Amenities</h4>
        <router-link
          :to="{
            name: 'add-amenity',
            params: { id: $route.params.id },
          }"
          v-can="'add-clinic-amenity'"
        >
          <button class="btn btn-success d-flex align-items-center">
            <i class="ri ri-hospital-fill mr-1"></i>
            <span>Add / Edit Amenity</span>
          </button>
        </router-link>
      </div>
      <div
        class="table-responsive mb-4"
        v-if="amenities && amenities?.length > 0"
      >
        <b-table
          striped
          bordered
          :items="amenities"
          :fields="amenities_fields"
          :sort-by.sync="sortBy"
          :per-page="0"
          :current-page="currentPage"
          :sort-desc.sync="sortDesc"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          responsive="sm"
          class="table-bordered table-hover"
          :key="key"
        >
          <template v-slot:cell(delete)="row" v-can="'delete-clinic-amenity'">
            <div @click.prevent="deleteAmenity(row.item.id)">
              <i class="mdi mdi-delete delete-i"></i>
            </div>
          </template>
        </b-table>
      </div>
      <div class="no_content py-4" v-else>
        <div style="display: grid; justify-items: center">
          <img src="/noData.svg" alt="" />
          <span class="no_content_text">No Data Available</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hospitalMixin from "@/mixins/ModuleJs/amenity";
import MixinRequest from "@/mixins/request";

export default {
  mixins: [hospitalMixin, MixinRequest],
  created() {
    this.fetchAmenitiesList();
  },
};
</script>
