var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-4",attrs:{"id":"notification"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-title-box d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v("Gallery")]),_c('router-link',{directives:[{name:"can",rawName:"v-can",value:('add-clinic-gallery'),expression:"'add-clinic-gallery'"}],attrs:{"to":{
          name: 'add-clinic-gallery',
          params: { id: _vm.$route.params.id },
        }}},[_c('button',{staticClass:"btn btn-danger d-flex align-items-center"},[_c('i',{staticClass:"ri ri-gallery-fill mr-1"}),_c('span',[_vm._v("Add Gallery")])])])],1),(_vm.clinic_gallery && _vm.clinic_gallery?.length > 0)?_c('div',{staticClass:"table-responsive mb-4"},[_c('b-table',{key:_vm.key,staticClass:"table-bordered table-hover",attrs:{"striped":"","bordered":"","items":_vm.clinic_gallery,"fields":_vm.clinic_gallery_fields,"sort-by":_vm.sortBy,"per-page":0,"current-page":_vm.currentPage,"sort-desc":_vm.sortDesc,"filter-included-fields":_vm.filterOn,"responsive":"sm"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(is_active)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"switch":"","checked":row.item.is_active == 1 ? true : false},on:{"change":function($event){return _vm.updateStatus(row.item.id)}}})]}},{key:"cell(delete)",fn:function(row){return [_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.deleteGallery(row.item.id)}}},[_c('i',{staticClass:"mdi mdi-delete delete-i"})])]}},{key:"cell(image_name)",fn:function(row){return [(row.item.image_name)?_c('img',{attrs:{"src":row.item.image_name,"height":"150px","width":"150px"}}):_c('div',[_vm._v("---")])]}},{key:"cell(edit)",fn:function(row){return [_c('router-link',{attrs:{"to":{
              name: 'edit-clinic-gallery',
              params: { id: _vm.$route.params.id },
              query: { hospitalId: row.item.id },
            }}},[_c('i',{staticClass:"fa fa-edit edit-i",staticStyle:{"color":"#5664d2","cursor":"pointer"}})])]}}],null,false,2740439854)})],1):_c('div',{staticClass:"no_content py-4"},[_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"grid","justify-items":"center"}},[_c('img',{attrs:{"src":"/noData.svg","alt":""}}),_c('span',{staticClass:"no_content_text"},[_vm._v("No Data Available")])])
}]

export { render, staticRenderFns }