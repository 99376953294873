import { clinics } from "../../js/path";

export default {
  data() {
    return {
      amenities_fields: [
        {
          key: "id",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "delete",
        },
      ],
      pageNo: null,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      currentPage: 1,
      params: "",
      key: 0,
      amenities: [],
      form: {
        amenity: [
          {
            id: 0,
            name: "",
          },
        ],
      },
    };
  },
  methods: {
    async deleteAmenity(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.amenities.findIndex((e) => e.id == id);
        const url = `${clinics?.getAmenities}/${id}`;
        const res = await this.postRequest(url, {
          _method: "delete",
        });
        if (res.status) {
          this.amenities.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: res.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchAmenitiesList() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getAmenities}/${this.$route.params.id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          this.amenities = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    addOption() {
      let length = this.form.amenity.length;
      this.form.amenity.push({
        id: length,
        name: "",
      });
    },
    removeOption(key) {
      if (key == 0) return;
      this.form.amenity.splice(key, 1);
    },
    async fetchServices(query) {
      this.$store.commit("loader/updateStatus", false);
      this.serviceList = [];
      if (query != null && query != "") {
        this.$store.commit("loader/updateStatus", true);
        const url = clinics?.getServiceList;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.serviceList = data.response.data;
        }
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchSingleHospital(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getAmenities}/${id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          console.log(responseData, "res");
          if (responseData?.length > 0) {
            this.form.amenity = responseData.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            });
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = clinics?.getAmenities;
        if (this.$route.name == "edit-amenity") {
          url = clinics?.getAmenities + "/" + this.$route.params.id;
        }
        let dataAppend = new FormData();
        dataAppend.append("amenity", JSON.stringify(this.form.amenity));
        dataAppend.append("clinic_id", this.$route.params.id);
        if (this.$route.name == "edit-amenity") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push(`/clinic/edit-clinic/${this.$route.params.id}`);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  created() {
    if (
      this.$route.name == "add-amenity" ||
      this.$route.name == "edit-amenity"
    ) {
      if (this.$route.name == "edit-amenity") {
        this.fetchSingleHospital(this.$route.params.id);
      }
    }
  },
};
