<template>
  <div class="row mt-4">
    <div class="col-12">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0">Timings</h4>
        <router-link
          :to="{
            name: 'add-hospital-timings',
            params: { id: $route.params.id },
          }"
          v-can="'add-clinic-timings'"
        >
          <button class="btn btn-info d-flex align-items-center">
            <i class="ri ri-hospital-fill mr-1"></i>
            <span class="fw-bold"> Add Timing </span>
          </button>
        </router-link>
      </div>
      <div
        class="table-responsive mb-4"
        v-if="hospitalTimings && hospitalTimings?.length > 0"
      >
        <b-table
          striped
          bordered
          :items="hospitalTimings"
          :fields="hospitalTimings_fields"
          :sort-by.sync="sortBy"
          :per-page="0"
          :current-page="currentPage"
          :sort-desc.sync="sortDesc"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          responsive="sm"
          class="table-bordered table-hover"
          :key="key"
        >
          <template v-slot:cell(day_status)="row">
            <img
              src="/tick.svg"
              height="20px"
              style="color: red"
              v-if="row.item.day_status == 1"
            />
            <img src="/close.svg" height="20px" style="color: red" v-else />
          </template>
          <template v-slot:cell(day)="row">
            <div v-if="row.item.day">{{ row.item.day }}</div>
            <div v-else>---</div>
          </template>
          <template v-slot:cell(swap_time)="row">
            <div v-if="row.item.swap_time">{{ row.item.swap_time }}</div>
            <div v-else>---</div>
          </template>
          <template v-slot:cell(swap_hours)="row">
            <div v-if="row.item.swap_hours">{{ row.item.swap_hours }}</div>
            <div v-else>---</div>
          </template>
          <template v-slot:cell(start_time_list)="row">
            <div v-if="row.item.start_time_list">
              {{ row.item.start_time_list }}
            </div>
            <div v-else>---</div>
          </template>
          <template v-slot:cell(end_time_list)="row">
            <div v-if="row.item.end_time_list">
              {{ row.item.end_time_list }}
            </div>
            <div v-else>---</div>
          </template>
          <template v-slot:cell(edit)="row" v-can="'edit-clinic-timings'">
            <router-link
              :to="{
                name: 'edit-hospital-timings',
                params: { id: row.item.clinic_id },
                query: { hospitalId: row.item.id },
              }"
            >
              <i
                class="fa fa-edit edit-i"
                style="color: #5664d2; cursor: pointer"
              ></i>
            </router-link>
          </template>
        </b-table>
      </div>
      <div class="no_content py-4" v-else>
        <div style="display: grid; justify-items: center">
          <img src="/noData.svg" alt="" />
          <span class="no_content_text">No Data Available</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hospitalMixin from "@/mixins/ModuleJs/hospital-timings";
import MixinRequest from "@/mixins/request";

export default {
  props: ["type"],
  mixins: [hospitalMixin, MixinRequest],
  created() {
    this.fetchHospitalTimingList();
  },
};
</script>
