import { clinics } from "../../js/path";

export default {
  data() {
    return {
      services_fields: [
        {
          key: "id",
        },
        {
          key: "name",
          label: "Service Name",
        },
        {
          key: "delete",
        },
      ],
      pageNo: null,
      status: false,
      activeTab: "all",
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      currentPage: 1,
      params: "",
      key: 0,
      services: [],
      form: {
        services: [],
      },
      serviceList: [],
    };
  },
  methods: {
    async deleteService(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.services.findIndex((e) => e.id == id);
        const url = `${clinics?.getServices}/${id}`;
        const res = await this.postRequest(url, {
          clinic_id: this.$route.params.id ?? "",
          _method: "delete",
        });
        if (res.status) {
          this.services.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: res.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchServicesList() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getServices}/${this.$route.params.id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          this.services = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    addOption() {
      let length = this.form.amenity.length;
      this.form.amenity.push({
        id: length,
        name: "",
      });
    },
    removeOption(key) {
      if (key == 0) return;
      this.form.amenity.splice(key, 1);
    },
    async fetchServices(query) {
      this.$store.commit("loader/updateStatus", false);
      this.serviceList = [];
      if (query != null && query != "") {
        this.$store.commit("loader/updateStatus", true);
        const url = clinics?.getServiceList;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.serviceList = data.response.data;
        }
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchSingleHospital(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getServices}/${id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData?.length > 0) {
            this.form.services = responseData?.map((item) => {
              return {
                id: item.id ?? "",
                name: item.name ?? "",
              };
            });
          }
        }
      } catch (err) {
        console.log(err);
        // this.$store.commit("toast/updateStatus", {
        //   status: true,
        //   icon: "error",
        //   title: err.data ? err.data.message : "Fetching error!",
        // });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = clinics?.getServices;
        if (this.$route.name == "edit-services") {
          url = clinics?.getServices + "/" + this.$route.params.id;
        }
        let dataAppend = new FormData();
        const services = [];
        this.form.services.map((item) => {
          services.push(item.id);
        });
        if (services?.length > 0)
          dataAppend.append("services", JSON.stringify(services));
        dataAppend.append("clinic_id", this.$route.params.id);
        if (this.$route.name == "edit-services") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push(`/clinic/edit-clinic/${this.$route.params.id}`);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  created() {
    if (
      this.$route.name == "add-services" ||
      this.$route.name == "edit-services"
    ) {
      if (this.$route.name == "edit-services") {
        this.fetchSingleHospital(this.$route.params.id);
      }
    }
  },
};
