<template>
  <div class="row mt-4" id="notification">
    <div class="col-12">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0">Gallery</h4>
        <router-link
          :to="{
            name: 'add-clinic-gallery',
            params: { id: $route.params.id },
          }"
          v-can="'add-clinic-gallery'"
        >
          <button class="btn btn-danger d-flex align-items-center">
            <i class="ri ri-gallery-fill mr-1"></i>
            <span>Add Gallery</span>
          </button>
        </router-link>
      </div>
      <div
        class="table-responsive mb-4"
        v-if="clinic_gallery && clinic_gallery?.length > 0"
      >
        <b-table
          striped
          bordered
          :items="clinic_gallery"
          :fields="clinic_gallery_fields"
          :sort-by.sync="sortBy"
          :per-page="0"
          :current-page="currentPage"
          :sort-desc.sync="sortDesc"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          responsive="sm"
          class="table-bordered table-hover"
          :key="key"
        >
          <template v-slot:cell(is_active)="row">
            <b-form-checkbox
              switch
              class="mb-1"
              @change="updateStatus(row.item.id)"
              :checked="row.item.is_active == 1 ? true : false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(delete)="row" v-can="'delete-clinic-gallery'">
            <div @click.prevent="deleteGallery(row.item.id)">
              <i class="mdi mdi-delete delete-i"></i>
            </div>
          </template>
          <template v-slot:cell(image_name)="row">
            <img
              :src="row.item.image_name"
              v-if="row.item.image_name"
              height="150px"
              width="150px"
            />
            <div v-else>---</div>
          </template>
          <template v-slot:cell(edit)="row" v-can="'edit-clinic-gallery'">
            <router-link
              :to="{
                name: 'edit-clinic-gallery',
                params: { id: $route.params.id },
                query: { hospitalId: row.item.id },
              }"
            >
              <i
                class="fa fa-edit edit-i"
                style="color: #5664d2; cursor: pointer"
              ></i>
            </router-link>
          </template>
        </b-table>
      </div>
      <div class="no_content py-4" v-else>
        <div style="display: grid; justify-items: center">
          <img src="/noData.svg" alt="" />
          <span class="no_content_text">No Data Available</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hospitalMixin from "@/mixins/ModuleJs/clinic-gallery";
import MixinRequest from "@/mixins/request";

export default {
  mixins: [hospitalMixin, MixinRequest],
  created() {
    this.fetchGalleryList();
  },
};
</script>
