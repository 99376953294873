<template>
  <div class="row mt-4" id="notification">
    <div class="col-12">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0">FAQS</h4>
        <router-link
          :to="{
            name: 'add-faqs',
            params: { id: $route.params.id },
          }"
          v-can="'add-clinic-faqs'"
        >
          <button class="btn btn-secondary d-flex align-items-center">
            <i class="ri ri-hospital-fill mr-1"></i>
            <span>Add FAQ</span>
          </button>
        </router-link>
      </div>
      <div class="table-responsive mb-4" v-if="faqs && faqs?.length > 0">
        <b-table
          striped
          bordered
          :items="faqs"
          :fields="faqs_fields"
          :sort-by.sync="sortBy"
          :per-page="0"
          :current-page="currentPage"
          :sort-desc.sync="sortDesc"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          responsive="sm"
          class="table-bordered table-hover"
          :key="key"
        >
          <template v-slot:cell(delete)="row" v-can="'delete-clinic-faqs'">
            <div @click.prevent="deleteFaq(row.item.id)">
              <i class="mdi mdi-delete delete-i"></i>
            </div>
          </template>
          <template
            v-slot:cell(is_active)="row"
            v-can="'update-clinic-faqs-status'"
          >
            <b-form-checkbox
              switch
              class="mb-1"
              @change="updateStatus(row.item.id)"
              :checked="row.item.is_active == 1 ? true : false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(edit)="row" v-can="'edit-clinic-faqs'">
            <router-link
              :to="{
                name: 'edit-faqs',
                params: { id: row.item.clinic_id },
                query: { hospitalId: row.item.id },
              }"
            >
              <i
                class="fa fa-edit edit-i"
                style="color: #5664d2; cursor: pointer"
              ></i>
            </router-link>
          </template>
        </b-table>
      </div>
      <div class="no_content py-4" v-else>
        <div style="display: grid; justify-items: center">
          <img src="/noData.svg" alt="" />
          <span class="no_content_text">No Data Available</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hospitalMixin from "@/mixins/ModuleJs/faq";
import MixinRequest from "@/mixins/request";

export default {
  mixins: [hospitalMixin, MixinRequest],
  created() {
    this.fetchFaqList();
  },
};
</script>
