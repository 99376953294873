<template>
  <div class="row mt-4" id="notification">
    <div class="col-12">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0">Member Tagging</h4>
        <router-link
          :to="{
            name: 'add-member-tagging',
            params: { id: $route.params.id },
          }"
          v-can="'add-clinic-members'"
        >
          <button class="btn btn-warning d-flex align-items-center">
            <i class="ri ri-user-2-fill mr-1"></i>
            <span>Add / Edit Member Tagging</span>
          </button>
        </router-link>
      </div>
      <div
        class="table-responsive mb-4"
        v-if="member_tagging && member_tagging?.length > 0"
      >
        <b-table
          striped
          bordered
          :items="member_tagging"
          :fields="member_tagging_fields"
          :sort-by.sync="sortBy"
          :per-page="0"
          :current-page="currentPage"
          :sort-desc.sync="sortDesc"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          responsive="sm"
          class="table-bordered table-hover"
          :key="key"
        >
        </b-table>
      </div>
      <div class="no_content py-4" v-else>
        <div style="display: grid; justify-items: center">
          <img src="/noData.svg" alt="" />
          <span class="no_content_text">No Data Available</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hospitalMixin from "@/mixins/ModuleJs/member-tagging";
import MixinRequest from "@/mixins/request";

export default {
  mixins: [hospitalMixin, MixinRequest],
  created() {
    this.fetchMembersList();
  },
};
</script>
