import { clinics } from "../../js/path";

export default {
  data() {
    return {
      meta_image: "",
      meta_image_url: "",
      edit: {
        meta_image_url: "",
      },
      pageNo: null,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      currentPage: 1,
      params: "",
      key: 0,
      patient_stories: [],
      patient_stories_fields: [
        {
          key: "id",
        },
        {
          key: "url",
        },
        {
          key: "meta_description",
          label: "Meta Description",
        },
        {
          key: "meta_title",
          label: "Meta Title",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      form: {
        patient_stories: [
          {
            url: "",
            meta_title: "",
            meta_description: "",
            meta_image: null,
            previewUrl: "",
          },
        ],
      },
      updateForm: {
        url: "",
        meta_title: "",
        meta_description: "",
      },
    };
  },
  methods: {
    readFile(e, txt) {
      if (txt == "meta_image") {
        this.meta_image = e.target.files[0];
        if (this.meta_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.meta_image.reset();
          this.meta_image_url = "";
          this.edit.meta_image_url = "";
          this.meta_image = "";
          return false;
        } else {
          this.meta_image_url = URL.createObjectURL(this.meta_image);
          this.edit.meta_image_url = URL.createObjectURL(this.meta_image);
        }
        return true;
      }
    },
    async deleteStory(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.patient_stories.findIndex((e) => e.id == id);
        const url = `${clinics?.getPatientStories}/${id}`;
        const res = await this.postRequest(url, {
          _method: "delete",
        });
        if (res.status) {
          this.patient_stories.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: res.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    handleFileChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded file is more than 2MB",
          });
          event.target.value = "";
          return;
        }
        this.$set(this.form.patient_stories, index, {
          url: this.form.patient_stories[index].url,
          meta_title: this.form.patient_stories[index].meta_title,
          meta_description: this.form.patient_stories[index].meta_description,
          meta_image: file,
          previewUrl: URL.createObjectURL(file),
        });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addPatientStory() {
      this.form.patient_stories.push({
        id: this.form.patient_stories.length,
        url: "",
        meta_title: "",
        meta_description: "",
        meta_image: null,
      });
    },
    removeImageStory(index) {
      if (this.form.patient_stories.length > 1) {
        this.form.patient_stories.splice(index, 1);
      }
    },
    async fetchSingleHospital() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getSingleStory}/${
          this.$route.query.hospitalId ?? ""
        }`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData) {
            this.updateForm.meta_title = responseData.meta_title;
            this.updateForm.meta_description = responseData.meta_description;
            this.updateForm.url = responseData.url;
            this.edit.meta_image_url = responseData.meta_image;
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      if (this.$route.name == "add-patient-stories" && this.$v.$invalid) {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getPatientStories}`;
        if (this.$route.name == "edit-patient-stories") {
          url = `${clinics?.getPatientStories}/${
            this.$route.query.hospitalId ?? ""
          }`;
        }
        let dataAppend = new FormData();
        if (this.$route.name == "edit-patient-stories") {
          dataAppend.append("meta_title", this.updateForm.meta_title ?? "");
          dataAppend.append(
            "meta_description",
            this.updateForm.meta_description ?? ""
          );
          dataAppend.append("url", this.updateForm.url ?? "");
          if (this.meta_image) {
            dataAppend.append("meta_image", this.meta_image);
          }
        }
        if (this.$route.name == "add-patient-stories") {
          this.form.patient_stories.forEach((item, index) => {
            dataAppend.append(`patient_stories[${index}][url]`, item.url);
            dataAppend.append(
              `patient_stories[${index}][meta_title]`,
              item.meta_title
            );
            dataAppend.append(
              `patient_stories[${index}][meta_description]`,
              item.meta_description
            );
            dataAppend.append(
              `patient_stories[${index}][meta_image]`,
              item.meta_image
            );
          });
        }
        dataAppend.append("clinic_id", this.$route.params.id);
        if (this.$route.name == "edit-patient-stories") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push(`/clinic/edit-clinic/${this.$route.params.id}`);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchStoriesList() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getPatientStories}/${this.$route.params.id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          this.patient_stories = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
};
