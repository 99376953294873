var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-title-box d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v("Timings")]),_c('router-link',{directives:[{name:"can",rawName:"v-can",value:('add-clinic-timings'),expression:"'add-clinic-timings'"}],attrs:{"to":{
          name: 'add-hospital-timings',
          params: { id: _vm.$route.params.id },
        }}},[_c('button',{staticClass:"btn btn-info d-flex align-items-center"},[_c('i',{staticClass:"ri ri-hospital-fill mr-1"}),_c('span',{staticClass:"fw-bold"},[_vm._v(" Add Timing ")])])])],1),(_vm.hospitalTimings && _vm.hospitalTimings?.length > 0)?_c('div',{staticClass:"table-responsive mb-4"},[_c('b-table',{key:_vm.key,staticClass:"table-bordered table-hover",attrs:{"striped":"","bordered":"","items":_vm.hospitalTimings,"fields":_vm.hospitalTimings_fields,"sort-by":_vm.sortBy,"per-page":0,"current-page":_vm.currentPage,"sort-desc":_vm.sortDesc,"filter-included-fields":_vm.filterOn,"responsive":"sm"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(day_status)",fn:function(row){return [(row.item.day_status == 1)?_c('img',{staticStyle:{"color":"red"},attrs:{"src":"/tick.svg","height":"20px"}}):_c('img',{staticStyle:{"color":"red"},attrs:{"src":"/close.svg","height":"20px"}})]}},{key:"cell(day)",fn:function(row){return [(row.item.day)?_c('div',[_vm._v(_vm._s(row.item.day))]):_c('div',[_vm._v("---")])]}},{key:"cell(swap_time)",fn:function(row){return [(row.item.swap_time)?_c('div',[_vm._v(_vm._s(row.item.swap_time))]):_c('div',[_vm._v("---")])]}},{key:"cell(swap_hours)",fn:function(row){return [(row.item.swap_hours)?_c('div',[_vm._v(_vm._s(row.item.swap_hours))]):_c('div',[_vm._v("---")])]}},{key:"cell(start_time_list)",fn:function(row){return [(row.item.start_time_list)?_c('div',[_vm._v(" "+_vm._s(row.item.start_time_list)+" ")]):_c('div',[_vm._v("---")])]}},{key:"cell(end_time_list)",fn:function(row){return [(row.item.end_time_list)?_c('div',[_vm._v(" "+_vm._s(row.item.end_time_list)+" ")]):_c('div',[_vm._v("---")])]}},{key:"cell(edit)",fn:function(row){return [_c('router-link',{attrs:{"to":{
              name: 'edit-hospital-timings',
              params: { id: row.item.clinic_id },
              query: { hospitalId: row.item.id },
            }}},[_c('i',{staticClass:"fa fa-edit edit-i",staticStyle:{"color":"#5664d2","cursor":"pointer"}})])]}}],null,false,969702405)})],1):_c('div',{staticClass:"no_content py-4"},[_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"grid","justify-items":"center"}},[_c('img',{attrs:{"src":"/noData.svg","alt":""}}),_c('span',{staticClass:"no_content_text"},[_vm._v("No Data Available")])])
}]

export { render, staticRenderFns }