import { clinics } from "../../js/path";

export default {
  data() {
    return {
      faqs_fields: [
        {
          key: "id",
        },
        {
          key: "question",
        },
        {
          key: "answer",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      pageNo: null,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      currentPage: 1,
      params: "",
      key: 0,
      faqs: [],
      form: {
        faqs: [
          {
            id: 0,
            question: "",
            answer: "",
            is_active: 0,
          },
        ],
      },
    };
  },
  methods: {
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.faqs.findIndex((e) => e.id === id);
        const url = `${clinics?.updateFaq}/${id}`;
        const data = await this.postRequest(url, {
          is_active: !this.faqs[index].is_active,
        });
        if (data.status) {
          this.faqs[index].is_active = !this.faqs[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    addFaq() {
      this.form.faqs.push({
        id: this.form.faqs.length,
        question: "",
        answer: "",
        is_active: 0,
      });
    },
    removeFaq(index) {
      if (this.form.faqs.length > 1) {
        this.form.faqs.splice(index, 1);
      }
    },
    async deleteFaq(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.faqs.findIndex((e) => e.id == id);
        const url = `${clinics?.getFaq}/${id}`;
        const res = await this.postRequest(url, {
          _method: "delete",
        });
        if (res.status) {
          this.faqs.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: res.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchFaqList() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getFaq}/${this.$route.params.id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          this.faqs = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchSingleHospital() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getSingleFaq}/${
          this.$route.query?.hospitalId ?? ""
        }`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData) {
            const faq = this.form.faqs[0];
            faq.id = responseData.id ?? "";
            faq.question = responseData.question ?? "";
            faq.answer = responseData.answer ?? "";
            faq.is_active = responseData.is_active == 1 ? true : false;
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = clinics?.getFaq;
        if (this.$route.name == "edit-faqs") {
          url = clinics?.getFaq + "/" + this.$route.query?.hospitalId ?? "";
        }
        let dataAppend = new FormData();
        if (this.$route.name == "edit-faqs") {
          dataAppend.append("question", this.form.faqs[0].question ?? "");
          dataAppend.append("answer", this.form.faqs[0].answer ?? "");
          dataAppend.append(
            "is_active",
            this.form.faqs[0].is_active == true ? 1 : 0
          );
        } else {
          dataAppend.append("faqs", JSON.stringify(this.form.faqs));
        }
        dataAppend.append("clinic_id", this.$route.params.id);
        if (this.$route.name == "edit-faqs") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push(`/clinic/edit-clinic/${this.$route.params.id}`);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
};
