<template>
  <div class="row mt-4" id="notification">
    <div class="col-12">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0">Patient Stories</h4>
        <router-link
          :to="{
            name: 'add-patient-stories',
            params: { id: $route.params.id },
          }"
          v-can="'add-clinic-patient-stories'"
        >
          <button class="btn btn-info d-flex align-items-center">
            <i class="ri ri-user-2-fill mr-1"></i>
            <span>Add Patient Story</span>
          </button>
        </router-link>
      </div>
      <div
        class="table-responsive mb-4"
        v-if="patient_stories && patient_stories?.length > 0"
      >
        <b-table
          striped
          bordered
          :items="patient_stories"
          :fields="patient_stories_fields"
          :sort-by.sync="sortBy"
          :per-page="0"
          :current-page="currentPage"
          :sort-desc.sync="sortDesc"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          responsive="sm"
          class="table-bordered table-hover"
          :key="key"
        >
          <template
            v-slot:cell(delete)="row"
            v-can="'delete-clinic-patient-stories'"
          >
            <div @click.prevent="deleteStory(row.item.id)">
              <i class="mdi mdi-delete delete-i"></i>
            </div>
          </template>
          <template v-slot:cell(meta_image)="row">
            <img :src="row.item.meta_image" height="150px" width="150px" />
          </template>
          <template
            v-slot:cell(edit)="row"
            v-can="'edit-clinic-patient-stories'"
          >
            <router-link
              :to="{
                name: 'edit-patient-stories',
                params: { id: $route.params.id },
                query: { hospitalId: row.item.id },
              }"
            >
              <i
                class="fa fa-edit edit-i"
                style="color: #5664d2; cursor: pointer"
              ></i>
            </router-link>
          </template>
        </b-table>
      </div>
      <div class="no_content py-4" v-else>
        <div style="display: grid; justify-items: center">
          <img src="/noData.svg" alt="" />
          <span class="no_content_text">No Data Available</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hospitalMixin from "@/mixins/ModuleJs/patient-stories";
import MixinRequest from "@/mixins/request";

export default {
  mixins: [hospitalMixin, MixinRequest],
  created() {
    this.fetchStoriesList();
  },
};
</script>
