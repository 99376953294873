import { clinics } from "../../js/path";

export default {
  data() {
    return {
      hospitalTimings_fields: [
        {
          key: "id",
        },
        {
          key: "day",
        },
        {
          key: "start_time_list",
          label: "Start time",
        },
        {
          key: "end_time_list",
          label: "End Time",
        },
        {
          key: "swap_hours",
        },
        {
          key: "swap_time_list",
          label: "Swap Time",
        },
        {
          key: "day_status",
        },
        {
          key: "edit",
        },
      ],
      pageNo: null,
      status: false,
      activeTab: "all",
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      hospitalTimings: [],
      currentPage: 1,
      params: "",
      key: 0,
      form: {
        schedule: [
          {
            day: "Monday",
            start_time: "",
            end_time: "",
            swap_hours: "",
            swap_time: "",
            day_status: 0,
          },
          {
            day: "Tuesday",
            start_time: "",
            end_time: "",
            swap_hours: "",
            swap_time: "",
            day_status: 0,
          },
          {
            day: "Wednesday",
            start_time: "",
            end_time: "",
            swap_hours: "",
            swap_time: "",
            day_status: 0,
          },
          {
            day: "Thursday",
            start_time: "",
            end_time: "",
            swap_hours: "",
            swap_time: "",
            day_status: 0,
          },
          {
            day: "Friday",
            start_time: "",
            end_time: "",
            swap_hours: "",
            swap_time: "",
            day_status: 0,
          },
          {
            day: "Saturday",
            start_time: "",
            end_time: "",
            swap_hours: "",
            swap_time: "",
            day_status: 0,
          },
          {
            day: "Sunday",
            start_time: "",
            end_time: "",
            swap_hours: "",
            swap_time: "",
            day_status: 0,
          },
        ],
      },
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // formatTime(input) {
    //   if (input) {
    //     let newDate = new Date(input);
    //     console.log(newDate, "newdate");
    //     const hours = newDate.getHours().toString().padStart(2, "0");
    //     const minutes = newDate.getMinutes().toString().padStart(2, "0");
    //     const seconds = newDate.getSeconds().toString().padStart(2, "0");
    //     if (hours && minutes && seconds) {
    //       return `${hours}:${minutes}:${seconds}`;
    //     }
    //   } else {
    //     return "00:00:00";
    //   }
    // },
    // prepareClinicTimings(clinicTimings) {
    //   return clinicTimings.map((item) => ({
    //     day: item?.day ?? "",
    //     start_time:
    //       item?.start_time !== "00:00:00"
    //         ? this.formatTime(item?.start_time)
    //         : "00:00:00",
    //     end_time:
    //       item?.end_time !== "00:00:00"
    //         ? this.formatTime(item?.end_time)
    //         : "00:00:00",
    //     swap_hours: item?.swap_hours ?? "",
    //     swap_time:
    //       item?.swap_time !== "00:00:00"
    //         ? this.formatTime(item?.swap_time)
    //         : "00:00:00",
    //     day_status: item?.day_status === 1 ? true : false,
    //   }));
    // },
    async fetchHospitalTimingList() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getTimings}/${this.$route.params.id}`;
        console.log(url, "url");
        const data = await this.getRequest(url);
        if (data.status) {
          this.hospitalTimings = data.response.data;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchSingleHospital(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getTimings}/${id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data?.response?.data;
          if (responseData?.length > 0) {
            this.form.schedule = responseData?.map((item) => {
              return {
                day: item?.day ?? "",
                start_time: item?.start_time ?? "",
                end_time: item?.end_time ?? "",
                swap_hours: item?.swap_hours ?? "",
                swap_time: item?.swap_time ?? "",
                day_status: item?.day_status === 1 ? true : false,
              };
            });
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    isObjectEmpty(obj) {
      return Object.values(obj).every(
        (value) => value === null || value === "" || value === undefined
      );
    },
    async submitData() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        const hasEmptyObject = this.form.schedule.some((obj) =>
          this.isObjectEmpty(obj)
        );
        let url = clinics?.getTimings;
        if (!hasEmptyObject) {
          url = clinics?.getTimings + "/" + this.$route.params.id;
        }
        let dataAppend = new FormData();
        dataAppend.append("schedule", JSON.stringify(this.form.schedule));
        dataAppend.append("clinic_id", this.$route.params.id);
        if (!hasEmptyObject) {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push(`/clinic/edit-clinic/${this.$route.params.id}`);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  created() {
    if (
      this.$route.name == "add-hospital-timings" ||
      this.$route.name == "edit-hospital-timings"
    ) {
      this.fetchSingleHospital(this.$route.params.id);
    }
  },
};
