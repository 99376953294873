var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-4",attrs:{"id":"notification"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-title-box d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v("Patient Stories")]),_c('router-link',{directives:[{name:"can",rawName:"v-can",value:('add-clinic-patient-stories'),expression:"'add-clinic-patient-stories'"}],attrs:{"to":{
          name: 'add-patient-stories',
          params: { id: _vm.$route.params.id },
        }}},[_c('button',{staticClass:"btn btn-info d-flex align-items-center"},[_c('i',{staticClass:"ri ri-user-2-fill mr-1"}),_c('span',[_vm._v("Add Patient Story")])])])],1),(_vm.patient_stories && _vm.patient_stories?.length > 0)?_c('div',{staticClass:"table-responsive mb-4"},[_c('b-table',{key:_vm.key,staticClass:"table-bordered table-hover",attrs:{"striped":"","bordered":"","items":_vm.patient_stories,"fields":_vm.patient_stories_fields,"sort-by":_vm.sortBy,"per-page":0,"current-page":_vm.currentPage,"sort-desc":_vm.sortDesc,"filter-included-fields":_vm.filterOn,"responsive":"sm"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(delete)",fn:function(row){return [_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.deleteStory(row.item.id)}}},[_c('i',{staticClass:"mdi mdi-delete delete-i"})])]}},{key:"cell(meta_image)",fn:function(row){return [_c('img',{attrs:{"src":row.item.meta_image,"height":"150px","width":"150px"}})]}},{key:"cell(edit)",fn:function(row){return [_c('router-link',{attrs:{"to":{
              name: 'edit-patient-stories',
              params: { id: _vm.$route.params.id },
              query: { hospitalId: row.item.id },
            }}},[_c('i',{staticClass:"fa fa-edit edit-i",staticStyle:{"color":"#5664d2","cursor":"pointer"}})])]}}],null,false,4198785395)})],1):_c('div',{staticClass:"no_content py-4"},[_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"grid","justify-items":"center"}},[_c('img',{attrs:{"src":"/noData.svg","alt":""}}),_c('span',{staticClass:"no_content_text"},[_vm._v("No Data Available")])])
}]

export { render, staticRenderFns }